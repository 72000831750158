import React from 'react'
import { Login } from '../components/login/Login';
import Head from 'next/head';
const LoginPage = () => {
    return (
        <>
        <Head>
        <meta name="robots" content="noindex, nofollow, noodp, noydir"/>
        </Head>
        <Login />
        </>
    )
}

export default LoginPage;
import { ChangeEvent, useState, useEffect } from 'react';
import styles from './Login.module.css';
import { Input as InputText } from '../ui';
import { API } from '@/libs/helpers/http-client';
import { setCookie, getCookie } from '@/libs/helpers/Cookie';
import { useGlobalContext } from '@/libs/context/GlobalContext';
import { useRouter } from 'next/router';

export function Login() {
    const { props: { pageURL, setLoading ,apiError, setApiError} } = useGlobalContext();
    const siteUrl = process.env.SITE_URL;
    const [showPassword, setShowPassword] = useState(false);
    const query = pageURL.split('?')[1] || ''
    const router = useRouter();
    const { project: projectId } = router.query;
    const [form, setForm] = useState({
        employeeId: '',
        password: '',
        appType: "TDD"
    });
    const [errors, setErrors] = useState({
        employeeId: '',
        password: ''
    });
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setApiError("")
        const { name, value } = e.target;
        setErrors({ ...errors, [name]: "" });
        setForm({ ...form, [name]: value });
    };
    const checkValidation = () => {
        const _errors = errors;
        let isValidate = true;
        if (!form.employeeId || form.employeeId.trim() == "") {
            _errors.employeeId = "Employee Id is required";
            isValidate = false;
        }
        else
            _errors.employeeId = "";
        if (!form.password || form.password.trim() == "") {
            _errors.password = "Password is required";
            isValidate = false;
        } else
            _errors.password = "";
        setErrors({ ..._errors });
        return isValidate;
    }

    const submitData = async (e: any) => {
        e && e.preventDefault();
        if (checkValidation()) {
            setLoading(true)
            const result = await API.post('/api/login', form);
            if (result  && result.data && !result.data.employeeId) {
                setLoading(false)
                setApiError(result.data.message)
            } else if (result && result.data && result.data.employeeId ) {
                if (result.data && projectId) {
                    const userDetails = result.data;
                    setApiError('')
                    setCookie('loginToken', JSON.stringify(userDetails), 7)
                    localStorage.setItem('userDetails', JSON.stringify(userDetails))
                    if (userDetails.roleIds.includes(process.env.BLOCKS_ADMIN)){
                        window.location.href = `${siteUrl}/home?project=${projectId}&usertype=designer`;
                    }else{
                        window.location.href = `${siteUrl}/home?project=${projectId}`;
                    }
                } else {
                    setLoading(false)
                    setApiError('ProjectId missing from URL')
                }
            } else {
                setLoading(false)
                setApiError('Something went wrong!')
            }
        } else {
            setLoading(false)
            return;
        }
    };

    useEffect(() => {
        const authToken = getCookie('loginToken');
        if (authToken) {
            router.push(`/home${query ? `?${query}` : ''}`);
        }
    }, []);

    return <form onSubmit={submitData}>
        <div className="twodLoginmainbox" >
            <div className={styles.twdLogininermain}>
                <div className={styles.twoDlohbgimbox}>
                    <img src="/assets/images/2ddloginpage.png" alt="" />
                </div>
                <div className={styles.tddWhitlogobox}>
                    <img className="img-responsive" src="/assets/images/2ddlogo.svg" alt="" />
                </div>
                <div className={styles.twoddLogindemain}>
                    <img className="img-responsive mb46" src="/assets/images/2ddbluelogo.svg" alt="" />
                    {/* <span className="text666 font16 dispBlok mb46">Transform your space. One block at a time</span> */}
                    <span className="text222 font20 dispBlok fbold mb16">Login</span>
                    <div className={errors["employeeId"] && `${styles.twoDformitm} ${styles.error}` || styles.twoDformitm}>
                        <InputText type="text" name="employeeId" value={form.employeeId} required={true} onChange={handleChange} pattern="^[a-zA-Z0-9 ]+$" maxLength={10} className={styles.twoDcontol} />
                        <label htmlFor="employeeId" className={styles.twoDfolable}>Enter Beats Employee ID to Continue</label>
                        <span className={styles.errorText}>{errors["employeeId"]}</span>
                    </div>
                    <div className={errors["password"] && `${styles.twoDformitm} ${styles.error}` || styles.twoDformitm}>
                        <InputText type={showPassword ? "text" : "password"} name="password" pattern="^$|^\S+.*" required={true} className={styles.twoDcontol} maxLength={50} value={form.password} onChange={handleChange} />
                        <label htmlFor="password" className={styles.twoDfolable}>Password</label>
                        <span className={styles.errorText}>{errors["password"]}</span>
                        <div className={styles.psswordShowbox} onClick={() => setShowPassword(!showPassword)}>{showPassword ? <img className="img-responsive" src="/assets/images/passwordhidelogo.svg" alt="" /> : <img className="img-responsive" src="/assets/images/passwordshowlogo.svg" alt="" />}
                        </div>
                    </div>
                    {apiError && <div className={styles.apiError}>{apiError}</div>}
                    <div className={`${styles.twDbuttprbox} mb16`} onClick={submitData}>
                        <button>Proceed to Login</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
}